import React from "react";
import Badge from '@salesforce/design-system-react/lib/components/badge';
import { FooterContext } from "./TrainingProvider";

function formatDate(date) {
  if (!date) {
    return "";
  }

  var options = { weekday: "short", year: "numeric", month: "short", day: "numeric" };
  let outDate = date.toLocaleDateString("en-US", options);
  outDate = outDate.split(",");
  return outDate[0] + ", " + outDate[1] + outDate[2];
}

function formatTime(date) {
  if (!date) {
    return "";
  }

  var options = { hour: "numeric", minute: "2-digit" };
  return date.toLocaleTimeString("en-US", options);
}

function formatDateTime(date) {
  if (!date) {
    return "";
  }

  return formatDate(date) + " @ " + formatTime(date);
}

function isEventToday(startTimestamp, endTimestamp) {
  const today = new Date();
  
  const todayDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
  const startDate = new Date(startTimestamp);
  const endDate = new Date(endTimestamp);
  
  // Strip time portion from start and end dates
  const startDateOnly = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const endDateOnly = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

  // Check if today is between or equal to the start and end dates
  return todayDate >= startDateOnly && todayDate <= endDateOnly;
}

function isStartingTomorrow(timestamp) {
  const today = new Date();
  const inputDate = new Date(timestamp);

  // Get tomorrow's date
  const tomorrow = new Date(today);
  tomorrow.setDate(today.getDate() + 1);

  return (
      tomorrow.getDate() === inputDate.getDate() &&
      tomorrow.getMonth() === inputDate.getMonth() &&
      tomorrow.getFullYear() === inputDate.getFullYear()
  );
}

export default class ServiceAppointmentCard extends React.Component {
  static contextType = FooterContext;

  constructor(props) {
    super(props);

    //Initial state
    this.state = {
      show: true,
    };

    //binding functions
  }

  componentDidMount() {}

  changeContext(action) {
    if (action !== null) {
      this.props.toggleDetails(this.props.data.Id);
    }
  }

  render() {
    if (!this.state.show) return null;

    let sa = this.props.data;

    let title = null;
    let action = null;

    let cardIcon = null;
    let cardSVGSymbol = null;

    let contractor = null;
    let attendee = null;
    /////////////////////////

    let startDate = new Date(sa.SchedStartTime.substr(0, 19) + "Z");
    let endDate = new Date(sa.SchedEndTime.substr(0, 19) + "Z");
    let dateRange = formatDateTime(startDate) + " → " + formatDateTime(endDate);
    let trainingAccount = null;
    let trainingAddress = null;
    let trainingCourse = null;
    let description = null;
    let isToday = false;
    let startsTomorrow = false;

    isToday = isEventToday(startDate, endDate);
    startsTomorrow = isStartingTomorrow(startDate);

    if (formatDate(startDate) === formatDate(endDate)) {
      // Only show final time when the same date
      dateRange = formatDateTime(startDate) + " → " + formatTime(endDate);
    }

    if (sa.hasOwnProperty("AppointmentNumber")) {
      if (sa.Contractor__c) {
        contractor = sa.Contractor__c;
      }

      title = sa.AppointmentNumber + " • " + formatDateTime(startDate);

      trainingAddress = sa.Training_Address1__r.Name.includes("Distance Learning")
        ? null //"Distance Learning"
        : sa.Training_Address1__r.Name;

      trainingAccount = sa.Account__r.Name;

      trainingCourse = sa.Product_Name__c;
      cardIcon = "record";
      cardSVGSymbol = "record";

      if (sa.IH3POS__c === "On-Site") {
        if (sa.Product_Family__c === "Evaluations") {
          attendee =
            sa.Attendees__r != null && sa.Attendees__r.length === 1
              ? sa.Attendees__r[0].Contact__r.FirstName +
                " " +
                sa.Attendees__r[0].Contact__r.LastName
              : null;

          action = "EvalServiceAppointment";

          cardIcon = "new-note";
          cardSVGSymbol = "new_note";
        } else {
          //TODO - temporary, treat like DLIH - JHSC
          if (sa.Product_Name__c.includes("DLOS - JHSC")) {
            action = "ServiceAppointment";
          } else {
            action = "OnSiteServiceAppointment";
          }
        }
      } else {
        action = "ServiceAppointment";
      }
    } else {
      description = sa.Type + (sa.Description ? "/" + sa.Description : "");

      title = sa.AbsenceNumber + " • " + description;

      cardIcon = "defer";
      cardSVGSymbol = "defer";
    }

    return (
      <article className="slds-card" onClick={() => this.changeContext(action)}>
        <div className="slds-card__header slds-grid" style={{ pointerEvents: "none" }}>
          <div className="slds-media slds-media_center slds-has-flexi-truncate">
            <div className="slds-media__figure">
              <span
                className={`slds-icon_container slds-icon_container--circle slds-icon-action-${cardIcon}`}
                title="account"
                style={{ borderRadius: "5px" }}
              >
                <svg className="slds-icon slds-icon_small" aria-hidden="true">
                  <use
                    xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#${cardSVGSymbol}`}
                  ></use>
                </svg>
              </span>
            </div>
            <div className="slds-media__body">
              <h2 className="slds-card__header-title">
                <div className="slds-card__header-link" title="Accounts">
                  <span>{title}</span>
                  {
                    isToday ? (
                      <Badge
                      id="is-today-badge"
                      color="success"
                      content="Today"
                      className="slds-m-left_x-small"
                    />
                    ) : null
                  }
                  {
                    startsTomorrow ? (
                      <Badge
                      id="is-tomorrow-badge"
                      color="warning"
                      content="Tomorrow"
                      className="slds-m-left_x-small"
                    />
                    ) : null
                  }
                </div>
              </h2>
            </div>
          </div>
        </div>

        {trainingCourse ? (
          <div className="slds-card__body slds-card__body_inner" style={{ marginTop: "0" }}>
            <b>Course: </b>
            {trainingCourse}
          </div>
        ) : null}

        {contractor ? (
          <div className="slds-card__body slds-card__body_inner" style={{ marginTop: "0" }}>
            <b>Contractor: </b>
            {contractor}
          </div>
        ) : null}

        {trainingAccount ? (
          <div className="slds-card__body slds-card__body_inner" style={{ marginTop: "0" }}>
            <b>Client: </b>
            {trainingAccount}
          </div>
        ) : null}

        {attendee ? (
          <div className="slds-card__body slds-card__body_inner" style={{ marginTop: "0" }}>
            {/* <Icon category="utility" color="warning" name="user" size="xx-small" /> */}
            {/* <span style={{ marginLeft: "5px", verticalAlign: "text-top" }}> */}
            <b>Attendee: </b>
            {attendee}
            {/* </span> */}
          </div>
        ) : null}

        {trainingAddress ? (
          <div className="slds-card__body slds-card__body_inner" style={{ marginTop: "0" }}>
            <b>Address: </b>
            {trainingAddress}
          </div>
        ) : null}

        {description ? (
          <div className="slds-card__body slds-card__body_inner" style={{ marginTop: "0" }}>
            <b>Description: </b>
            {description}
          </div>
        ) : null}

        {dateRange ? (
          <div className="slds-card__body slds-card__body_inner" style={{ marginTop: "0" }}>
            <b>Date: </b>
            {dateRange}
          </div>
        ) : null}
      </article>
    );
  }
}

import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import Input from '@salesforce/design-system-react/lib/components/input'; 
import APIWorker from '../APIWorker';
import React from 'react';
import {BodyContext} from '../TrainingProvider';

export default class SendConsentFormModal extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            email : this.props.record.Contact__r.Email !== null ? this.props.record.Contact__r.Email : '',
            changedEmail : false,
            emailErrorMessage : ''
        }

        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.validateAttendee = this.validateAttendee.bind(this);
        this.checkChangedFields = this.checkChangedFields.bind(this);
        this.sendConsentForm = this.sendConsentForm.bind(this);
        this.clearErrors = this.clearErrors.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    showErrorAlert(){
        this.context.showErrorAlert();
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    closeModal(){
        this.context.changeModal('');
    }

    validateAttendee(){
        this.clearErrors();

        if(this.state.email === ''){
            this.setState({
                emailErrorMessage : 'Email is a required field!'
            });
        }
        else if(/^.+@.+\..+$/.test(this.state.email) !== true && this.state.email !== ''){
            this.setState({
                emailErrorMessage : 'Email format is invalid! (ex. john@gmail.com)'
            });  
        }
        else{
            this.showSpinner();

            this.checkChangedFields();
        }
    }

    checkChangedFields(){
        if(this.state.changedEmail){
            let json = {
                "Email" : this.state.email
            };

            APIWorker.updateContact(this.props.record.Contact__c, json)
            .then(res => {
                this.sendConsentForm();
            })
            .catch(error => {
                this.showErrorAlert();
            });
        }
        else{
            this.sendConsentForm();
        }
    }

    sendConsentForm(){
        let json = {
            "Force_Send_Consent_Form__c" : true
        };

        APIWorker.updateAttendee(this.props.record.Id, json)
        .then(res => {
            this.hideSpinner();
            this.closeModal();
            this.context.showSuccessAlert('Consent Form will be sent shortly!');
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }

    clearErrors(){
        this.setState({
            emailErrorMessage : ''
        });
    }

    render(){
        return(
            <Modal isOpen={true} heading="Send Consent Form" ariaHideApp={false} align="center" size="large"
            footer={[
                <Button key="1" label="Cancel" onClick={() =>this.context.changeModal('')} />,
                <Button key="2" label="Send Email" onClick={() => this.validateAttendee()} variant="brand"/>
            ]}>
                <div>
                    <article className="slds-card">
                        <div className="slds-card__header slds-grid">
                            <div className="slds-media slds-media_center slds-has-flexi-truncate">
                                <div className="slds-media__figure">
                                    <span className={`slds-icon_container slds-icon_container--circle slds-icon-action-add-contact`} title="account">
                                        <svg className="slds-icon slds-icon_small" aria-hidden="true">
                                            <use xlinkHref={`/assets/icons/action-sprite/svg/symbols.svg#add_contact`}></use>
                                        </svg>
                                    </span>                            
                                </div>
                                <div className="slds-media__body">
                                    <h2 className="slds-card__header-title">
                                        <b>{this.props.record.Name}</b>
                                    </h2>
                                    <span>{this.props.record.Company__c}</span>                                    
                                </div>
                                <div className="slds-media__body">

                                    <Input
                                        label="Email"
                                        placeholder="ex. john@gmail.com"
                                        type="text"
                                        errorText={this.state.emailErrorMessage}
                                        onChange={(event, data) => {
                                            this.setState({
                                                email : data.value,
                                                changedEmail : true
                                            });
                                        }}
                                        value={this.state.email}
                                    />                        

                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </Modal>
        )
    }

}
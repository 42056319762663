import Modal from '@salesforce/design-system-react/lib/components/modal';
import Button from '@salesforce/design-system-react/lib/components/button';
import APIWorker from '../APIWorker';
import React from 'react';
import {BodyContext} from '../TrainingProvider';

export default class CloseSAModal extends React.Component{
    static contextType = BodyContext;

    constructor(props){
        super(props);

        this.state = {
            data : [],
            noCheckedAttendees : true,
            disabled : true,
            cardDetailId : this.props.cardDetailId,
            closedSA : this.props.record[0].Status === "Completed" ? true : false,
            isLoading : false,
            numberOfToyotaQuestionnaires : 0,
            numberOfEvaluations : 0,
            message : this.props.record[0].Status === "Completed" ? 'This Service Appointment has been successfully completed!' : 'Reminder: Ensure any late attendees have been checked in with with theory status/eval status (if applicable)'
        }

        this.showSpinner = this.showSpinner.bind(this);
        this.hideSpinner = this.hideSpinner.bind(this);
        this.validateData = this.validateData.bind(this);
        this.showErrorAlert = this.showErrorAlert.bind(this);
    }

    componentDidMount(){
        this.showSpinner();
        var numberOfToyotaQuestionnaires = 0;
        var numberOfEvaluations = 0;

        APIWorker.getServiceAppointment(this.state.cardDetailId)
        .then(res => {
            console.log(res.data);
            numberOfToyotaQuestionnaires = res.data[0].Number_of_Toyota_Questionnaires__c;
        })
        .then(res => {
            APIWorker.getServiceAppointmentEvalutations(this.state.cardDetailId)
            .then(res => {
                console.log(res.data);
                numberOfEvaluations = res.data.length;
            })
            .then(res => {
                APIWorker.getCheckedAttendees(this.state.cardDetailId)
                .then(res => {
                    this.setState({
                        data : res.data,
                        noCheckedAttendees : res.data.length === 0 ? true : false,
                        numberOfToyotaQuestionnaires,
                        numberOfEvaluations
                    },
                        () => {
                            this.hideSpinner();
                            this.validateData();
                        }
                    )
                })
                .catch(error => {
                    this.showErrorAlert();
                    this.setState({
                        disabled : true,
                        message : 'There was an error. Please try again later.'
                    });
                });
            })
            .catch(error => {
                this.showErrorAlert();
                this.setState({
                    disabled : true,
                    message : 'There was an error. Please try again later.'
                });
            });  
        })
        .catch(error => {
            this.showErrorAlert();
            this.setState({
                disabled : true,
                message : 'There was an error. Please try again later.'
            });
        });        
    }

    showSpinner(){
        this.context.showSpinner();
    }

    hideSpinner(){
        this.context.hideSpinner();
    }

    showErrorAlert(){
        this.context.showErrorAlert();
    }

    validateData(){
        let appointmentNumber = this.props.record[0].AppointmentNumber;
        let product = this.props.record[0].Product_Name__c;
        let productFamily = this.props.record[0].Product_Family__c;
        let accountName = this.props.record[0].Account__r.Name;
        let assignedServiceResourceId = this.props.record[0].Assigned_Service_Resource__c;
        let contractor = this.props.record[0].Contractor__c ? this.props.record[0].Contractor__c : '';
        let needsResult = false;

        if((product.includes("JHSC") || product.includes("WAH")) && !product.includes("JHSCII") && !product.includes("JHSCR") && !product.includes('JHSCI1') && !product.includes('JHSCI2')){
            needsResult = true;
        }
        

        //Check all requirements to close course and set boolean to false if any requirements not met, otherwise defaults to true
        let passedTest = true;

        if(this.state.noCheckedAttendees && ((productFamily === 'Courses' && !product.includes("RFT")) || (productFamily === 'Evaluations' && !accountName.includes('Toyota Motor')))){
            this.setState({
                message : 'Service Appointment cannot be closed until attendees have been checked in / evaluations have been entered.'
            });
            passedTest = false;
        }
        else if(this.state.numberOfEvaluations === 0 && productFamily === 'Evaluations' && !accountName.includes('Toyota Motor')){
            this.setState({
                message : 'Service Appointment cannot be closed until attendees have an evaluation entered.'
            });
            passedTest = false;
        }
        else if(accountName.includes('Toyota Motor') && this.state.numberOfToyotaQuestionnaires < 1){
            this.setState({
                message : 'Service Appointments for Toyota require a questionnaire to be completed prior to closing SA!'
            });
            passedTest = false;
        }
        else if(assignedServiceResourceId === '00546000000u1NoAAI' && contractor === ''){
            this.setState({
                message : 'Service Appointments assigned to OSG Contractor needs a specific contractor set prior to closing SA! Contact <a href="mailto:trainingadmin@osg.ca?' + encodeURI('subject=Set Contractor on ' + appointmentNumber) + '">trainingadmin@osg.ca or training coordinators</a> to resolve.'
            });
            passedTest = false;
        }
        else if(!this.state.noCheckedAttendees){
            this.state.data.map((attendee) => {
                if(attendee.Theory_Status__c !== 'Passed' && attendee.Theory_Status__c !== 'Failed' && productFamily === 'Courses'){
                    this.setState({
                        message : 'Service Appointment cannot be closed until all attendees have a theory status set.'
                    });
                    passedTest = false;
                }
            });
        }
        else if(needsResult){
            this.state.data.map((attendee) => {
                if(attendee.Theory_Exam_Results__c === null || attendee.Theory_Status__c === 'Pending'){
                    this.setState({
                        message : 'Service Appointment cannot be closed until attendees for JHSC/WAH have their exam status and results entered in.'
                    });
                    passedTest = false;
                }
            });
        }

        if(passedTest){
            this.setState({
                disabled : false
            })
        }
    }

    closeSA(){
        this.showSpinner();

        let json = {
            "Status" : "Completed"
        };

        APIWorker.updateServiceAppointment(this.props.cardDetailId, json)
        .then(res =>{
            this.setState({
                closedSA : true,
                disabled : true            },
                () => {
                    this.hideSpinner();
                    this.context.changeModal('');
                    this.context.showSuccessAlert('Service Appointment completed!');                
                }
            );
            this.context.changeFooter("CompletedServiceAppointment");
        })
        .catch(error => {
            this.showErrorAlert();
        });
    }


    render(){
        return(
            <div>

                <Modal isOpen={true} heading="Complete Appointment" ariaHideApp={false} align="center" size="large"
                footer={[
                    <Button key="1" label="Cancel" onClick={() =>this.context.changeModal('')} />,
                    <Button key="2" disabled={this.state.disabled} label="Complete SA" onClick={() => this.closeSA()} variant="brand"/>
                ]}>
                    <div className="slds-m-around_medium">
                        {/*{this.state.message}*/}
                        <div dangerouslySetInnerHTML={{__html:this.state.message}}></div>
                    </div>
                </Modal>
            </div>
        )
    }
}